.stepThree_container {
    margin-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .arrowLeft {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .verification_success {
        width: 100%;
        text-align: center;
        svg {
            margin-bottom: 50px;
        }

        h3 {
            color: #3A434B;
            font-size: 24px;
            font-family: "DM Sans";
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.2px;
            line-height: 24px; /* 100% */
        }

        p {
            color: #8B8699;
            font-size: 14px;
            font-family: "DM Sans";
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.4px;
            line-height: 24px; /* 171.429% */
        }
    }
}