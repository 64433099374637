.failed_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 20%;

    .verification_failed {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .box {
            width: 74px;
            height: 74px;
            background-color: #fff;
            border: 3px solid #000;
            border-radius: 50%;
            position: relative;
            margin-bottom: 50px;


            &::before,
            &::after {
              content: "";
              position: absolute;
              width: 4px;
              height: 35px;
              background-color: #E43535;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }


            &::after {
              transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        h3 {
            color: #3A434B;
            font-size: 18px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.2px;
            line-height: 24px;
        }

        p {
            color: #3A434B;
            text-align: center;
            font-style: normal;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            letter-spacing: 0.4px;
        }
    }

    @media (min-width: 1000px) {
        .verification_failed {
            h3 {
                font-size: 24px;
            }
        }
    }
}
