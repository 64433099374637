//loan offer
.loan_schedule {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;

  .title {
    color: #041644;
    font-size: 16px;
    font-family: "DM Sans";
    font-weight: 700;
    font-style: normal;
    line-height: 125%;
    border-bottom: 1px solid #d7dce0;
    grid-column: span 2;
    padding: 0 30px;
    padding-bottom: 12px;
    padding-top: 10px;
    grid-column: 1 / -1;

    @media (min-width: 760px) {
      font-size: 18px;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .loan_schedule_data {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding-left: 30px;

    .serial_number {
      width: 20px;
      height: 20px;
      background-color: #81909d;
      border-radius: 50%;
      text-align: center;
    }

    // @media (max-width: 290px) and (max-height: 653px) {
    //   padding-left: 20px;
    // }

    .schedule_amount_date {
      display: flex;
      flex-direction: column;

      small {
        color: #81909d;
        font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      strong {
        color: #0d0f11;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        font-family: "DM Sans";
        line-height: 150%;
      }
    }
  }

  .view_more_schedule_btn_container {
    grid-column: 1 / -1;
    // padding-bottom: 20px;
    // padding-top: 10px;

    .view_more_schedule_btn {
      background-color: #713fff;
      color: #fff;
      padding: 5px 10px;
      border-radius: 10px;
    }
  }
}

.loanAmountInputField {
  width: 100%;
  height: 55px;
  border-radius: 10px;
  border: 1px solid #d9d6e0;
  padding: 0 10px;

  &:hover {
    border-color: #713fff;
  }
}

.stepFour_container {
  width: 100%;
  height: 100%;
  // margin-top: 50%;
  text-align: center;

  .verification_failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      width: 74px;
      height: 74px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 50%;
      position: relative;
      margin-bottom: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 35px;
        background-color: #e43535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  .user_options {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      &:first-child {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        display: flex;
        width: 100%;
        height: 50px;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #713fff;
      }

      &:last-child {
        color: #713fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        display: flex;
        width: 100%;
        height: 50px;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #562eed;
        background: #f8f5ff;
      }
    }
  }
}

// loan status styles
.approved {
  color: rgb(87, 227, 196);
}

.rejected {
  color: rgb(244, 49, 49);
}

.awaiting {
  color: orange;
}

.statues {
  text-transform: capitalize;
}

.active {
  color: #25ad25;
}

.pagination_controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 8px;

  button {
    display: flex;
    height: 32px;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #f1f1f1;

    &:first-child,
    &:last-child {
      border: none;
    }

    &:nth-child(2) {
      background: #713fff;
      color: #fff;
    }

    &:nth-child(5) {
      border: none;
    }
  }
}

.red_dot {
  position: absolute;
  top: 10px;
  right: 50px;
}

.loanOffer_container {
  width: 100%;
  height: max-content;
  background: #f8f5ff;
  flex-shrink: 0;
  border-radius: 10px;

  .loanOffer_overview {
    width: 100%;
    display: flex;
    flex-direction: column;

    .loan_amount_outer_container {
      border-bottom: 1px solid #d7dce0;

      .loan_amount_container {
        color: #041644;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 40px */
        padding: 24px 32px 0 32px;

        @media (min-width: 1000px) {
          font-size: 32px;
        }
      }
    }

    .more_loan_details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      padding: 24px 32px 40px 32px;

      .loan_detail {
        display: flex;
        flex-direction: column;

        span:first-child {
          color: #645e77;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 12px */
        }

        span:last-child {
          color: #0d0f11;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
        }
      }
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;

  button {
    width: 100%;
    border-radius: 10px;
    height: 45px;
  }
}

.offer_verification_failed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .box {
    width: 74px;
    height: 74px;
    background-color: #fff;
    border: 3px solid #000;
    border-radius: 50%;
    position: relative;
    margin-bottom: 50px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 35px;
      background-color: #e43535;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  h4 {
    color: #3a434b;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.2px;
    line-height: 24px;
  }

  p {
    color: #3a434b;
    text-align: center;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }
}

.offer_verification_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 50px;
  }

  h4 {
    color: #3a434b;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    font-style: normal;
  }

  p {
    color: #3a434b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: center;
  }
}
