.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1000px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
    overflow: scroll;
  }

  .modal_steps_wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.stepOne_container {
  margin-top: 50px;

  @media (min-width: 1000px) {
    margin-top: 0;
  }
  width: 100%;
  height: 100%;
  display: flex;
  //   align-items: center;
  flex-direction: column;

  .stepOne_header {
    text-align: center;
    margin-bottom: 42px;

    @media (min-width: 1000px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
    }
  }

  .isLoading_container {
    width: 100%;
    display: flex;
    padding: 24px;
    gap: 7.106px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #f8f5ff;
  }

  .history_container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;

    .loan_card {
      display: flex;
      padding: 16px;
      width: 100%;
      align-items: center;
      // justify-content: space-between;
      border-radius: 20px;
      border: 1px solid #f4f5f6;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
      background: var(--White, #fff);
      cursor: pointer;

      @media (max-width: 360px) {
        padding: 10px;
      }

      .logoAndAmount {
        width: 100%;
        display: flex;
        align-items: end;
        gap: 16px;

        @media (max-width: 320px) {
          gap: 10px;
        }

        .amountAndDate {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          .amount {
            color: #170d33;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
          }

          .date {
            color: #8b8699;
            font-style: normal;
            font-size: 12px;
            line-height: normal;
            font-weight: 400;

            @media (max-width: 320px) {
              font-size: 10px;
            }
          }
        }
      }

      .icon_container {
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #713fff;
        img {
          text-align: center;
        }
      }

      .flagNrefNo {
        display: flex;
        flex-direction: column;
        align-items: start;

        .loanRef {
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
      }

      .repayBtn {
        color: #562eed;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

.pagination_controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 8px;

  button {
    display: flex;
    height: 32px;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #f1f1f1;

    &:first-child,
    &:last-child {
      border: none;
    }

    &:nth-child(2) {
      background: #713fff;
      color: #fff;
    }

    &:nth-child(5) {
      border: none;
    }
  }
}

.stepTwo_container {
  margin-top: 50px;
  @media (min-width: 1000px) {
    margin-top: 0;
  }
  width: 100%;
  height: 100%;
  display: flex;
  //   align-items: center;
  flex-direction: column;

  .stepTwo_header {
    text-align: center;

    @media (min-width: 1000px) {
      margin-top: 20px;
      text-align: left;
    }
    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
    }
  }

  .dueAmountContainer {
    height: 132px;
    flex-shrink: 0;
    border-radius: 18.38px;
    background: #f8f5ff;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .title {
      color: #8b8699;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .credit_balance {
      color: #200865;
      strong {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    @media (min-width: 1000px) {
      width: 462px;

      .title {
        color: #8b8699;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .credit_balance {
        color: #200865;
        strong {
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  .stepTwo_form {
    text-align: left;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // gap: 50px;

    .input_container {
      display: flex;
      flex-direction: column;

      span {
        color: rgb(204, 10, 10);
      }
    }

    .input_label_container {
      display: flex;
      flex-direction: column;
      flex: 1;

      label {
        font-size: 90%;
        color: #8b8699;
        line-height: 12px;
      }

      .input_field {
        display: block;
        height: 40px;
        padding: 0 0.7rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #1e2022;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d7dce0;
        border-radius: 10px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        width: 100%;
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .submit_button {
      width: 100%;
      border-radius: 10px;
      height: 50px;
      margin-top: 200px;
      margin-bottom: 20px;

      @media (min-width: 1000px) {
        margin-top: 200px;
        margin-bottom: 20px;
      }

      @media (max-width: 290px) and (max-height: 653px) {
        margin-top: 100px;
      }

      @media (max-width: 375px) and (max-height: 667px) {
        margin-top: 100px;
      }
    }
  }
}

.stepThree_container {
  margin-top: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .stepTwo_header {
    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .role {
    width: 100%;

    .wallet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      border: 0.5px solid #e8e7ea;
      padding: 16px;
      margin-top: 24px;
      cursor: pointer;

      &:hover {
        background-color: #f8f5ff;
      }

      .group1 {
        display: flex;
        align-items: center;
        gap: 16px;

        .walletContainer {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-color: #f8f5ff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      border: 0.5px solid #e8e7ea;
      padding: 16px;
      margin-top: 24px;
      cursor: pointer;

      &:hover {
        background-color: #f8f5ff;
      }

      .group2 {
        display: flex;
        align-items: center;
        gap: 16px;

        .cardContainer {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-color: #f8f5ff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    @media (min-width: 1026px) {
      max-width: 464px;
    }

    @media (max-width: 280px) and (max-width: 653px) {
      .wallet {
        padding: 8px;
        margin-top: 16px;

        .group1 {
          gap: 8px;

          .walletContainer {
            height: 40px;
            width: 40px;
          }
        }
      }

      .card {
        padding: 8px;
        margin-top: 16px;

        .group2 {
          gap: 8px;

          .cardContainer {
            height: 40px;
            width: 40px;
          }
        }
      }
    }

    .text {
      p {
        &:first-child {
          color: var(--G-100, #170d33);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
          font-family: "DM Sans";

          @media (max-width: 280px) and (max-width: 653px) {
            font-size: 16px;
          }
        }

        &:last-child {
          font-size: 14px;
          font-weight: 400;
          color: #8b8699;
          text-align: left;

          @media (max-width: 280px) and (max-width: 653px) {
            font-size: 10px;
          }
        }
      }
    }

    .onlyChild {
      color: var(--G-100, #170d33);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      font-family: "DM Sans";

      @media (max-width: 280px) and (max-width: 653px) {
        font-size: 16px;
      }
    }
  }

  .submit_button {
    margin-top: 100px;

    @media (max-width: 360px) and (max-height: 740px) {
      margin-top: 60px;
    }

    @media (max-width: 540px) and (max-height: 720px) {
      margin-top: 60px;
    }

    @media (max-width: 290px) and (max-height: 653px) {
      margin-top: 20px;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      margin-top: 60px;
    }
  }

  .backArrow {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }

  .addCard {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    color: #713fff;
    margin-bottom: 20px;
  }
}

.stepFour_container {
  position: relative;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1000px) {
      top: 0;
      left: 0;
    }
  }

  .stepThree_header {
    padding-top: 50px;
    text-align: center;

    @media (min-width: 1000px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      color: #8b8699;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .loanAmount_container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 132px;
    flex-shrink: 0;
    border-radius: 18.38px;
    background-color: #200865;
    color: #fff;

    .title {
      text-align: center;
      font-style: normal;
      font-size: 16px;
      line-height: normal;
      font-weight: 400;
    }

    .credit_balance {
      strong {
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
      }
    }

    @media (min-width: 1000px) {
      width: 462px;

      .title {
        text-align: center;
        font-style: normal;
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
      }

      .credit_balance {
        strong {
          color: #fff;
          font-size: 32px;
          font-weight: 600;
          font-style: normal;
          line-height: normal;
        }
      }
    }
  }

  .submit_button {
    width: 100%;
    border-radius: 10px;
    height: 50px;
    margin-top: 200px;
    margin-bottom: 20px;

    @media (min-width: 1000px) {
      margin-top: 200px;
      margin-bottom: 20px;
    }

    @media (max-width: 290px) and (max-height: 653px) {
      margin-top: 100px;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      margin-top: 100px;
    }
  }
}

.stepFive_container {
  width: 100%;
}

.stepSix_container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 70px;
}

.stepSeven_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .verification_success {
    img {
      margin-bottom: 50px;
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  @media (min-width: 1000px) {
    .verification_success {
      img {
        margin-bottom: 50px;
      }

      h3 {
        font-size: 24px;
      }
    }
  }
}

.stepEight_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .verification_failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      width: 74px;
      height: 74px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 50%;
      position: relative;
      margin-bottom: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 35px;
        background-color: #e43535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  .submit_button {
    margin-top: 100px;
  }

  @media (min-width: 1000px) {
    .verification_failed {
      h3 {
        font-size: 24px;
      }
    }
  }
}

.stepNine_container {
  width: 100%;
  //   padding-top: 50px;

  .submit_button {
    width: 100%;
    border-radius: 10px;
    height: 50px;
    margin-top: 200px;
    margin-bottom: 20px;

    @media (min-width: 1000px) {
      margin-top: 200px;
      margin-bottom: 20px;
    }

    @media (max-width: 290px) and (max-height: 653px) {
      margin-top: 100px;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      margin-top: 100px;
    }
  }
}

.backArrow {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.alert {
  width: 100%;
  padding-top: 40px;

  @media (min-width: 1000px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
