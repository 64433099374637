.isLoading_container {
    width: 100%;
    display: flex;
    padding: 24px;
    gap: 7.106px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #F8F5FF;

    @media (min-width: 1000px) {
        width: 464px;
    }
}

.noLoanHistory_container {
    display: flex;
    padding: 24px;
    gap: 7.106px;
    align-items: center;
    border-radius: 20px;
    background: #F8F5FF;

    .content_wrap {
        // text-align: center;
        width: 100%;

        p {
            color: #000;
            font-size: 18px;
            line-height: normal;
            font-weight: 700;
        }

        button {
            display: flex;
            justify-content: center;
            padding: 2px 8px;
            align-items: center;
            border-radius: 7.106px;
            font-size: 18px;
            background: #713FFF;
            color: #fff;
        }
    }

    img {
        display: none;
    }

    @media (min-width: 1000px) {
        padding: 10px;
        gap: 4px;

        .content_wrap {
            place-self: start;
            width: 295px;

            p {
                color: #000;
                font-size: 24px;
                line-height: normal;
                font-weight: 700;
            }

            button {
                font-size: 16px;
            }
        }

        img {
            display: block;
            object-fit: cover;
        }
    }
}

.overview_header {

    h1 {
        color: #200865;
        font-style: normal;
        font-size: 28px;
        line-height: 125%;
        font-weight: 700;
    }
}

.loan_content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;

    .transactions {
        display: none;
    }

    .dashboardAction {
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .arrowDown {
            width: 24px;
            height: 24px;
        }
    }
}

.card_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

@media (min-width: 1000px) {

    .loan_content {
        gap: 32px;
        margin-top: 35px;

        .transactions {
            display: block;
        }

        .dashboardAction {
            display: none;
        }
    }

    .card_container {
        width: 674px;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px;
    }
}
