.stepTwo_container {
    .stepTwo_header {
        h3 {
            color: #170D33;
            font-family: "DM Sans";
            font-weight: 700;
            font-style: normal;
            line-height: 150%;
            font-size: 20px;
        }
    }
    .stepTwo_form {
        width: 100%;
        margin-top: 30px;
    }

}

.submit_button {
    margin-top: 20px;
    height: 45px;
    color: #fff;
    background-color: #713FFF;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}