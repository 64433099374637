.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1000px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
  }

  .back_button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .modal_header {
    margin-top: 50px;
    text-align: center;

    @media (min-width: 1000px) {
      text-align: left;
    }
    h3 {
      color: #170d33;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
    }

    @media (min-width: 1000px) {
      margin-top: 20px;
    }
  }

  .isLoading_container {
    width: 100%;
    padding: 24px;
    gap: 7.106px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #f8f5ff;
    display: flex;

    @media (min-width: 1000px) {
      width: 464px;
    }
  }

  table {
    width: 100%;
    display: block;
    border-collapse: collapse;
    margin-top: 16px;
    overflow: hidden;

    td {
      text-align: left;
      width: 100%;

      &:first-of-type {
        color: var(--G-100, #170d33);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 16px */
        font-family: "DM Sans";
      }

      &:last-of-type {
        display: flex;
        align-items: center;
        gap: 16px;
        // text-align: right;
        color: #8b8699;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */

        .date {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 7px;

          .dueDate {
            white-space: nowrap;
          }
        }
      }

      padding: 10px;
      border-bottom: 1px solid #e1e1e1;
    }
  }
}

button {
  border: none;
  background-color: transparent;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
