.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1000px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
    overflow: scroll;
  }

  .back_button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.container {
  width: 100%;
  .container_header {
    padding-top: 30px;
    text-align: center;

    @media (min-width: 1000px) {
      text-align: left;
    }
    h3 {
      color: #170d33;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
      font-family: "DM Sans";
    }
  }

  .transaction {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    padding: 10px 0;

    .icon_amount_wrap {
      display: flex;
      align-items: center;
      gap: 10px;

      .withdrawalIcon_wrap {
        width: 40px;
        flex-shrink: 0;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fce9e9;
        border-radius: 50%;
      }

      .amount_date {
        display: flex;
        flex-direction: column;

        .transaction_date {
          color: #8b8699;
          font-size: 12px;
        }
      }
    }

    .success_status {
      color: #27be63;
    }

    .other_status {
      color: rgb(172, 18, 18);
    }

    @media (max-width: 430px) {
      margin-top: 10px;
    }
  }
}

.submit_button {
  text-align: center;
  margin-top: 50px;
  width: 100%;
  display: flex;

  button {
    height: 50px;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
