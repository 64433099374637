.widgetMainWrapper {
  //display: flex;
  //height: 100vh;
  //max-height: 100vh;
  //width: 100vw;
  //background-color: rgba(0, 0, 0, 0.5);
  //overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .widgetWrapper {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;

    .cancelIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      z-index: 9999999;
      color: #000;
      font-size: 2rem;
    }

    .widget {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #0d0f11;
      height: auto;
      max-height: 75vh;
      width: auto;
      background-color: #fff;
      border-radius: 24px;
      padding: 1.5rem;
      overflow-y: auto;

      @media only screen and (max-width: 768px) {
        max-height: 95vh;
        width: 90vw;

        //General css for tab and mobile
        background-color: #fff;
        border-radius: 24px;
        padding: 1rem;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
          border: 1px solid #713fff;
          border-radius: 5px;
          background-color: #fff;
        }

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #713fff;
        }
      }

      @media only screen and (max-width: 576px) {
        width: 90vw;
      }

      &::-webkit-scrollbar-track {
        border: 1px solid #713fff;
        border-radius: 5px;
        background-color: #fff;
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #713fff;
      }
    }
  }
}

#pin {
  background: #ffffff !important;
  border: 1px solid #d7dce0 !important;
  border-radius: 10px !important;
}

.icon,
.iconMobile {
  width: 34px !important;
  height: 34px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*.iconMobile {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 3rem !important;
    height: 3rem !important;
  }
}*/

#advancly-button {
  background: red;
}

.linkBankstatement {
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  gap: 10px;
  background: #fef4e5;
  margin-bottom: 20px;

  span {
    text-align: left;
    color: #c37e0b;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }

  button {
    padding: 4px 8px;
    border-radius: 24px;
    background: #f5a623;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}

.addCard {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: #713fff;
  margin-bottom: 20px;
}

.red_dot {
  position: absolute;
  top: -4px;
  right: -20px;
}
