.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: max-content;
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1000px) {
    width: 300px;
    height: 370px;
    top: 240px;
    right: 180px;
    padding: 40px 32px;
    transform: none;
    left: auto;
  }

  .modal_wrapper {
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #bab7c5;

      h4 {
        font-size: 18px;
      }

      .cancel_button {
        padding-bottom: 5px;
        cursor: pointer;
      }
    }

    .form {
      width: 100%;
      margin-top: 20px;
      .startDate {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      }

      .endDate {
        display: flex;
        flex-direction: column;
      }

      input {
        appearance: none;
        height: 40px;
        border-radius: 5px;
        padding: 0 10px;
        border: 1px solid #8b8699;
      }
    }

    .btn {
      margin-top: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        width: 100%;
        color: #fff;
        background-color: #200865;
        height: 50px;
        border-radius: 10px;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
