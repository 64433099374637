.new_loan_popup_container {
  max-width: 298px;
  height: 64px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fef4e5;
  box-shadow: 0 8px 24px 0 rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  overflow: hidden;

  .new_loan_popup {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .popup_text {
      color: #c37e0b;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }

    .popup_btn {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      padding: 4px 8px;
      border-radius: 24px;
      background-color: #c37e0b;
    }
  }
}
