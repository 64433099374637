.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 80px);
  width: 70%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (max-width: 560px) {
    width: 98%;
  }
  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin: 5px;

    p {
      font-size: 16px;
    }

    button {
      background-color: #4caf50;
      color: #ffffff;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
    }

    button:disabled {
      background-color: #a0a0a0;
      cursor: not-allowed;
    }

    @media (max-width: 560px) {
      p {
        font-size: 12px;
        font-weight: bold;
      }

      button {
        padding: 4px 8px;
      }
    }
  }
}

.closeIgreeConsent {
  color: #fff;
  background-color: #713fff;
}
