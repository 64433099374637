.warningCompleteVirtualAccount {
  background-color: #f8f5ff;
  border: 1px solid #713fff;

  padding: 0.75rem 0.75rem;
  margin: 0.25rem 0.25rem;

  @media (max-width: 360px) {
    padding: 0.2rem;
  }

  .prompt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 400px) {
      flex-direction: column;
    }

    p {
      margin-top: 12px;
    }
  }
}

.proceedNumber {
  border: none;
  color: #713fff;
  font-weight: 700;
  cursor: pointer;

  i {
    margin-left: 0.2rem;
  }
}

.overview_header {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .avatar {
    height: 32px;
    width: 32px;
    border: 2px solid #6666ff;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .noAvatar {
    height: 32px;
    width: 32px;
    // border: 2px solid #6666FF;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .greeting {
    color: #3a434b;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    font-weight: 700;
    letter-spacing: -0.16px;
  }
}

.dashboard_content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .transactions {
    display: none;
  }

  .dashboardAction {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .arrowDown {
      width: 24px;
      height: 24px;
    }
  }
}

.card_container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

@media (min-width: 1000px) {
  .overview_header {
    height: 100%;
    display: flex;
    align-items: center;

    .avatar {
      height: 32px;
      width: 32px;
      border: 2px solid #6666ff;
      border-radius: 50%;
      margin-bottom: 12px;
    }

    .noAvatar {
      height: 32px;
      width: 32px;
      // border: 2px solid #6666FF;
      border-radius: 50%;
      margin-bottom: 12px;
    }

    .greeting {
      font-size: 24px;
      letter-spacing: -0.24px;
    }
  }
  .dashboard_content {
    gap: 32px;
    margin-top: 35px;

    .transactions {
      display: block;
    }

    .dashboardAction {
      display: none;
    }
  }

  .card_container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }
}
