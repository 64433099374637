.businessInfo__container {

    .businessInfo_header {
        margin-top: 16px;
        width: 100%;

        h1 {
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            margin-top: 24px;
            color: #200865;

            @media (min-width: 1024px) {
                font-size: 32px;
            }
        }

        p {
            font-size: 14px;
            font-style: normal;
            margin-top: 8px;
            font-weight: 400;
        }
    }

    .businessInfo_form {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;

        .regNo {
            position: relative;

            .regError {
                position: absolute;
                bottom: -10px;
                color: red;
            }
        }

        .account_status {
            position: absolute;
            margin: 20px 0;
            color: #713FFF;
            cursor: pointer;
            bottom: 130px;
        }

        .fullName {
            display: flex;
            flex-direction: column;
        }

        .user {
            display: flex;
            align-items: center;
            gap: 8px;

            margin-bottom: 10px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        @media (min-width: 1000px) {
            width: 464px;

            .fullName {
                flex-direction: row;
                justify-content: space-between;
                gap: 20px;
            }

            .genderXCountry {
                flex-direction: row;
                justify-content: space-between;
                gap: 20px;
            }
        }


    }
}



.verify_phone {
    margin-bottom: 14px;

    .phone_label {
        font-size: 90%;
        color: #8B8699;
        line-height: 12px;
    }

    .selected_option {
        display: flex;
        align-items: center;
        gap: 16px;

        .flag {
            display: flex;
            gap: 10px;
            align-items: center;
            height: 40px;
            border: 0.5px solid #D1CFD6;
            border-radius: 10px;
            padding: 0 0.5rem;
            cursor: pointer;
            width: 115px;

            .iconify {
                margin-bottom: 1px;
                flex-shrink: 0;
                width: 16px;
                height: 10.643px;
            }

            .arrow {
                display: flex;
                width: 16px;
                height: 16px;
                justify-content: center;
                align-items: center;
                margin-bottom: 1px;
                flex-shrink: 0;
            }

            span {

                &:nth-child(2) {
                    color: #8B8699;
                    font-style: normal;
                    font-size: 14px;
                    width: 35px;
                }
            }
        }

        input {
            height: 40px;
            border: 0.5px solid #D1CFD6;
            border-radius: 10px;
            padding: 0 0.5rem;
        }

        input[type="tel"] {
            width: 100%;
            color: #000;

            @media (min-width: 1000px) {
                width: 333px;
            }
        }

        input[type="tel"]:read-only {
            background-color: #f8fafd;
        }
    }

    ol {
        list-style: none;
        overflow: overlay;
        max-height: 23rem;
        padding: 0;
        margin: 0;

        &::webkit-scrollbar {
            width: 0.6rem;
        }

        &::-webkit-scrollbar-thumb {
            width: 0.4rem;
            height: 3rem;
            background-color: #ccc;
            border-radius: .4rem;
        }

        li {
            padding: 1rem;
            align-items: center;
            display: flex;
            cursor: pointer;
            justify-content: space-between;

            div {
                display: flex;
                gap: 10px;
                align-items: center;

                .iconify {
                    margin-bottom: 1px;
                    flex-shrink: 0;
                    width: 16px;
                    height: 10.643px;
                }
            }

            &:hover {
                background-color: #bba4ff;
            }
        }
    }
    .phoneNumberValidation_error {
        color: rgb(205, 3, 3);
    }
}

.search_box {
    background-color: #200865;
    color: #fff;
    width: 100%;
    height: 50px;
    border: 0.5px solid #D1CFD6;
    border-radius: 10px 10px 0 0;
    padding: 0 0.5rem;
}

.options {
    position: relative;
    width: 100%;
    background-color: #F8F5FF;
    border-radius: .5rem;
    margin-top: 8px;

    &::before {
        position: absolute;
        content: "";
        left: 1rem;
        top: -1.1rem;
        height: 0;
        width: 0;
        border: .6rem solid transparent;
        border-bottom-color: #200865;
    }
}

.alternate_dob_container {
    margin-top: 8px;
    margin-bottom: 14px;

    .alternate_dob_label {
        font-size: 90%;
        color: #8B8699;
        line-height: 12px;
    }

    input {
        height: 40px;
        border: 0.5px solid #D1CFD6;
        border-radius: 10px;
        padding: 0 0.5rem;
    }

    input[type="date"] {
        width: 100%;
        color: #000;
    }

    input[type="date"]:read-only {
        background-color: #f8fafd;
    }

    .dobValidation_error {
        color: rgb(205, 3, 3);
    }
}

.submit__btn {
    margin-top: 40px;
    margin-bottom: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
        height: 50px;
        // color: #fff;
        // background-color: #713FFF;
        font-weight: 500;
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

    .login {
        margin-top: 20px;
    }
}
