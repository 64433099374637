.warningCompleteVirtualAccount {
  background-color: #f8f5ff;
  border: 1px solid #713fff;

  padding: 0.75rem 0.75rem;
  margin: 0.25rem 0.25rem;
}

.proceedNumber {
  border: none;
  color: #713fff;
  font-weight: 700;
  cursor: pointer;
  i {
    margin-left: 0.5rem;
  }
}
