.widgetErrorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 75%;
  background-color: #fff;
  .subContainer {
    background-color: rgb(242, 214, 214);
    border: 1px solid rgb(242, 214, 214);
    color: red;
    border-radius: 10px;
    padding: 10px;
  }
  button {
    cursor: pointer !important;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    background-color: #200865;
    &:hover {
      color: aliceblue;
    }
  }
}
