.password_container {
    width: 100%;
    position: relative;
    padding: 10px;

    .stepOne_container {
        width: 100%;

        .header {
            margin-bottom: 30px;
            h3 {
                color: #170D33;
                font-size: 20px;
                font-family: "DM Sans";
                font-weight: 700;
                font-style: normal;
                line-height: 150%;
            }
        }
    }

    .align {
        text-align: start;

        .header {
            margin-top: 10%;
            display: flex;
            flex-direction: column;

            h3 {
                color: #170D33;
                font-size: 20px;
                font-family: "DM Sans";
                font-weight: 700;
                font-style: normal;
                line-height: 150%;
                place-self: center;
            }
        }
    }

    .stepTwo_container {
        display: flex;
        flex-direction: column;
        .header {
            margin-bottom: 30px;
            h3 {
                color: #170D33;
                font-size: 20px;
                font-family: "DM Sans";
                font-weight: 700;
                font-style: normal;
                line-height: 150%;
            }
        }

        .otp {
            place-self: end;
            border: none;
            padding: 0;
            color: #713FFF;
            background-color: transparent;
            box-shadow: none;
        }

    }

    .stepThree_container {
        .stepOne_header {
            h3 {
                color: #170D33;
                font-size: 20px;
                font-family: "DM Sans";
                font-weight: 700;
                font-style: normal;
                line-height: 150%;
            }
        }
    }

    @media (min-width: 1000px) {
        width: 512px;
    }
}

.stepThree_container {
    width: 100%;
}

.submit__btn {
    margin-top: 40px;
    margin-bottom: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
        height: 50px;
        // color: #fff;
        // background-color: #713FFF;
        font-weight: 500;
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

}

.btn {
    margin-top: 70px;
}

.stepFour_container {
    margin-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .arrowLeft {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .verification_success {
        width: 100%;
        text-align: center;
        svg {
            margin-bottom: 50px;
        }

        h3 {
            color: #3A434B;
            font-size: 24px;
            font-family: "DM Sans";
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.2px;
            line-height: 24px; /* 100% */
        }

        p {
            color: #8B8699;
            font-size: 14px;
            font-family: "DM Sans";
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.4px;
            line-height: 24px; /* 171.429% */
        }
    }
}

.left {
    position: absolute;
    top: 0;
    left: 20px;
    cursor: pointer;
}

.alignLeft {
    text-align: start;
}

.alignment {
    padding-top: 20px;
}
