.bankStatement_body {
  display: flex;
  // padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  p {
    color: #170d33;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  button {
    display: flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #713fff;
    color: #fff;
  }
}
