.resetPin_container {
  width: 100%;

  .resetPin_header {
    h3 {
      color: #170d33;
      font-weight: 700;
      font-style: normal;
      line-height: 150%;
      font-size: 20px;
      font-family: "DM Sans";
    }
  }

  .resetPin_form {
    width: 100%;

    .selectQuestionContainer {
      width: 100%;
      position: relative;
      margin-bottom: 8px;

      .questionDropdown {
        max-height: 150px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      .selectedQuestionField {
        width: 100%;
        border: 1px solid #d1cfd6;
        padding: 6px 10px;
        border-radius: 8px;
        font-size: 16px;
        &::placeholder {
          color: #8c98a4;
          opacity: 1;
        }
      }
      .question_error {
        display: inline-block;
        color: rgb(205, 3, 3);
        margin-top: 4px;
        font-size: 13px;
      }
    }
  }
}

.submit_button {
  margin-top: 20px;
  height: 45px;
  color: #fff;
  background-color: #713fff;
  font-weight: 500;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.left {
  text-align: start;
}
