.transaction_container {
    width: 750px;

    .transaction_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 680px;

        .title {
            color: #0D0F11;
            font-style: normal;
            font-size: 24px;
            line-height: 150%;
            font-weight: 700;
        }

        .button {
            color: #713FFF;
            font-style: normal;
            font-size: 14px;
            line-height: 150%;
            font-weight: 400;
            margin-top: -5px;
            border: none;
            background-color: transparent;
        }
    }

    table {
        display: block;
        width: 100%;
        border-collapse: collapse;
        margin-top: 16px;
        overflow: hidden;
    
        .table_header {
            width: 100%;
            background-color: #F2F5F9;
            overflow: hidden;
        }

        tr {
            &:hover td:not(:last-child) {
                background-color: #F2F5F9;
            }
        }
    
        th, td {
            text-align: left;
            width: 25%;
        }
    
        th {
            padding: 12px 24px;
            border-top-left-radius: 10px;
    
            &:nth-child(2) {
                 border-top-left-radius: 0;
            }
    
            &:nth-child(3) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            &:nth-child(4) {
                border-top-left-radius: 0;
                border-top-right-radius: 10px;
            }
        }
    
        td {
            padding: 16px 24px;
        }

        .noTransaction_container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 10px;

            h3 {
                font-size: 20px;
            }
        }
    }
    
}

.flagActive {
    color: #25AD25;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  
  .flagPending {
    color: #0822a1;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .flagRejected {
    color: #fe5e2e;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .flagStale {
    color: #4a4848;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .viewInfo {
    cursor: pointer;
  }
