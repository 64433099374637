.profile_container {
    width: 100%;
    margin-bottom: 20px;

    .profile_header {
        h1 {
            color: #200865;
            font-family: "DM Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%;
        }
    }

    .profile_menu_header {
        padding-top: 50px;
        padding-left: 20px;
        width: 100%;
        height: 108px;
        display: flex;
        gap: 32px;
        flex-shrink: 0;
        border-radius: 20px 20px 0px 0px;
        background-color: #F8F5FF;

        @media (max-width: 290px) and (max-height: 654px) {
            gap: 18px;
        }

        .photo_wrapper {
            width: 80px;
            flex-shrink: 0;
            height: 80px;
            border-radius: 50%;
            border: 2px solid #713FFF;
            position: relative;

            .photo {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }

            .pen_holder {
                position: absolute;
                width: 30px;
                height: 30px;
                // flex-shrink: 0;
                border-radius: 50%;
                background-color: #713FFF;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 0;
                right: 0;
                cursor: pointer;
                border: 1px solid #fff;

                .pen {
                    width: 10.909px;
                    height: 10.909px;
                    flex-shrink: 0;
                    object-fit: cover;
                    text-align: center;
                }
            }
        }

        .name_email {
            display: flex;
            flex-direction: column;

            span {
                &:first-child {
                    color: #000;
                    font-size: 18px;
                    font-family: "DM Sans";
                    font-weight: 700;
                    font-style: normal;
                    line-height: 125%;
                }
                &:last-child {
                    color: #8B8699;
                    font-size: 14px;
                    font-family: "DM Sans";
                    font-weight: 400;
                    font-style: normal;
                    line-height: 125%;
                }
            }
        }

        @media (max-width: 290px) {
            padding-top: 70px;

            .photo_wrapper {
                width: 60px;
                flex-shrink: 0;
                height: 60px;

                .pen_holder {
                    width: 20px;
                    height: 20px;
                    .pen {
                        width: 8.909px;
                        height: 8.909px;
                        flex-shrink: 0;
                        object-fit: cover;
                        text-align: center;
                    }
                }
            }

            .name_email {
                display: flex;
                flex-direction: column;

                span {
                    &:first-child {
                        font-size: 14px;
                    }
                    &:last-child {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .profile_content_wrapper {
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: scroll;

        .menu_content_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%; /* Set width to 100% */

            .menu_wrapper {
                display: none;

                @media (min-width: 1000px) {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                }
            }

            .menu_item {
                border-bottom: 2px solid #ccc;
                margin-top: 12px;
                padding: 0 22px 5px 40px;
                cursor: pointer;
                flex-shrink: 0;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.active {
                    border-bottom: 2px solid #713FFF;
                }
            }

            .profile_content {
                place-self: start;
                margin-top: 32px;
                width: 100%;
                overflow: scroll;
            }

            .select {
                width: 100%;
                display: flex;
                height: 48px;
                border: 1px solid #8B8699;
                border-radius: 10px;
                position: relative;

                select {
                    width: 100%;
                    appearance: none;
                    padding: 0.4375rem 0.9375rem;
                    border: 0;
                    background-color: transparent;
                    font-size: 18px;
                    color: #8B8699;

                }

                img {
                    position: absolute;
                    height: 24px;
                    width: 24px;
                    top: 13px;
                    right: 10px;
                }

                @media (min-width: 1000px) {
                    display: none;
                }
            }
        }

    }

    @media (min-width: 1000px) {
        width: 900px;
        margin-bottom: 0;

        .profile_header {
            h1 {
                font-size: 28px;
            }
        }

        .profile_menu_header {
            padding-top: 32px;
            padding-left: 76px;

            .photo_wrapper {
                width: 120px;
                flex-shrink: 0;
                height: 120px;
            }

            .name_email {
                display: flex;
                flex-direction: column;

                span {
                    &:first-child {
                        font-size: 24px;
                    }
                }
            }

        }

        .profile_content_wrapper {
            margin-top: 32px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .menu_content_wrapper {
                width: 512px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

            .menu_wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .menu_item {
                border-bottom: 2px solid #ccc;
                padding: 0 22px 5px 40px;
                cursor: pointer;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            .menu_item {
                &.active {
                    border-bottom: 2px solid #713FFF;
                }
            }

            .profile_content {
              place-self: start;
              margin-top: 32px;
            }
          }
        }
    }
}


