:root {
  --primary-color: "#713fff";
  --primary-light-color: "#E3D9FF";
  --primary-deep-color: "#29067d";
}

body {
  font-family: "DM Sans", sans-serif !important;
}

.form-avatar {
  position: relative;
  width: 89px;
  height: 89px;
  border-radius: 100%;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer !important;
}

.status-button {
  padding: 2px 4px;
  border-radius: 0.25rem;
  text-transform: capitalize;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-orange {
  color: #ffc31a;
}
.color-mid-blue {
  color: var(--primary-color);
}
/* new UI color */
.color-mid-purple {
  color: var(--primary-color);
}
.text-primary {
  color: var(--primary-color) !important;
}
.color-white {
  color: #fff;
}
.color-green {
  color: #27be63;
}
.color-black {
  color: #000;
}
.color-grey {
  color: #3a434b;
}

.btn {
  border-color: var(--primary-color) !important;
}

.btn-primary {
  color: #fff !important;
  background-color: var(--primary-color) !important;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: var(--primary-light-color) !important;
}

.advancly-btn {
  color: #fff;
  background-color: var(--primary-color);
}
.advancly-btn:hover {
  color: #fff;
  background-color: var(--primary-light-color);
}
.advancly-btn-nobg {
  background-color: #fff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.advancly-btn-nobg-noborder {
  background-color: #fff;
  color: var(--primary-color);
  border: none;
}
.advancly-btn-nobg:hover {
  color: #fff;
  background-color: var(--primary-light-color);
}

.cursor-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.form-group label,
label.label-txt {
  font-size: 90%;
  line-height: 12px;
  color: #8b8699;
}

.pincode-input-container {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.pincode-input-container .pincode-input-text {
  border: 2px solid #b4babe !important;
  height: 48px;
  width: 48px;
  background: white !important;
  border-radius: 5px;
  color: #3a434b;
}

.pincode-input-container .pincode-input-text:focus {
  box-shadow: 0px 7px 16px rgba(171, 180, 189, 0.23);
  border: 2px solid #713fff !important;
}

.title {
  font-size: 24px;
  color: #0d0f11;
  font-weight: 600;
}

.padding-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.btn-radius {
  border-radius: 10px !important;
}

.icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon__green {
  background-color: #27be63;
}
.icon__orange {
  background-color: #ea6262;
}
.icon__light {
  background-color: #43aaf4;
}
.icon__primary {
  background-color: var(--primary-color);
}
.icon__secondary {
  background-color: #200865;
}
.icon__lightOrange {
  background-color: #ff5e2e;
}

.icon__light__blue {
  background-color: var(--primary-light-color);
}

.cursor {
  cursor: pointer !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-primary-dark {
  background-color: var(--primary-deep-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-primary-dark {
  color: #0d0f11 !important;
}

.ant-input,
.ant-select,
.ant-picker,
.ant-select-selector,
.react-tel-input .form-control,
.ant-input-affix-wrapper {
  border-radius: 10px !important;
  margin-bottom: 0.5rem !important;
  overflow: hidden !important;
  height: 48px !important;
  background-color: transparent !important;
}

.ant-form-item-label > label {
  color: #81909d !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  /* background: aquamarine; */
  display: flex;
  align-items: center;
}

.walletCard {
  width: 100%;
  height: 132px;
  background: url("../assets/dashboard/Shapes.png") #200865;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 18.3801px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor__back {
  font-size: inherit;
}

/*#mobile .ant-form-item-label > label {
  font-size: 2rem !important;
}

#mobile .btn {
  padding: 2rem 2rem !important;
  font-size: 2rem !important;
}*/

#mobile .ant-input-number-lg input {
  height: 48px !important;
}

#mobile .ant-input-number-lg {
  border-radius: 10px !important;
}

#browser .ant-input,
#browser .ant-select,
#browser .ant-picker,
#browser .ant-select-selector,
#browser .react-tel-input .form-control,
#browser .ant-input-affix-wrapper {
  height: 3.5rem !important;
}
#browser .ant-input-number-lg input {
  height: 3.5rem !important;
}

#browser .ant-input-number-lg {
  border-radius: 10px !important;
}

#browser .ant-upload-list-item-info {
  width: 70% !important;
  display: flex;
  justify-content: center;
}

#browser .ant-upload-list-item {
  display: flex !important;
  justify-content: center !important;
}

#browser .ant-upload.ant-upload-select {
  display: flex !important;
  justify-content: center !important;
}

/*#mobile .ant-input,
#mobile .ant-select,
#mobile .ant-picker,
#mobile .ant-select-selector,
#mobile .react-tel-input .form-control,
.ant-input-affix-wrapper {
  height: 8rem !important;
  font-size: 2rem !important;
}*/

/*#mobile .ant-form-item-explain,
#mobile .ant-form-item-extra {
  font-size: 2rem !important;
}*/

/*#mobile .account__name,
#mobile .title {
  font-size: 3.5rem !important;
}*/

/*#mobile .pincode-input-container #mobile .pincode-input-text {
  height: 8rem !important;
  width: 8rem !important;
}*/

/*#mobile .card {
  !*height: auto !important;*!
}

#mobile .walletCard {
  height: 12rem !important;
}

#mobile .walletCard p {
  font-size: 1rem !important;
}

#mobile span {
  font-size: 0.8rem !important;
}

#mobile p {
  font-size: 1rem !important;
}*/

.toastErrorMobile {
  /*font-size: 2rem !important;*/
}

.toastError,
.ant-message {
  /*font-size: 1rem !important;*/
}

textarea#textarea.ant-input {
  height: auto !important;
}

.ant-input-number-input,
.ant-input-number {
  height: 48px !important;
  border-radius: 10px !important;
}

/* .select {
  position: relative;
}

.select select {
  appearance: none;
  font-size: 16px;
  color: #81909d;
}

.select .form-label {
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 8px;
}

.select img {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 35px;
    right: 10px;
} */

.crimson {
  color: rgb(182, 4, 4);
}

.ant-alert-close-icon {
  overflow: visible !important;
  margin-top: -12px !important;
}

.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn > span {
  /* display: inline-block; */
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.anticon {
  margin-top: -8px !important;
}

.bank_account_name:focus {
  box-shadow: 0 0 5px #713fff;
  transition: box-shadow 0.3s ease-in-out;
}

.bank_account_name:disabled {
  color: #aba7b5;
}

.ant-form-item {
  margin-bottom: 0 !important;
}
