.transactionPin_container {
    width: 100%;
    position: relative;
    padding: 10px;

    .stepOne_container {
        .stepOne_header {
            h3 {
                color: #170D33;
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                line-height: 150%;
            }
        }

    }

    @media (min-width: 1000px) {
        width: 512px;
    }
}

.left {
    position: absolute;
    top: 0;
    left: 20px;
    cursor: pointer;
}

.alignment {
    padding-top: 20px;
}
