.bankStatement_container {
  width: 100%;
  position: relative;
  padding: 10px;

  .header {
    h3 {
      color: #170d33;
      font-size: 20px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      line-height: 150%;
    }
  }

  .body {
    display: flex;
    // padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    p {
      color: #170d33;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .alignLeft {
      text-align: left;
    }

    button {
      display: flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #713fff;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.2px;
    }
  }

  @media (min-width: 1000px) {
    width: 512px;
  }
}

.left {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.alert {
  width: 100%;
  padding-bottom: 20px;
  padding-top: 30px;

  @media (min-width: 1000px) {
    padding-top: 10px;
  }
}
