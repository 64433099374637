.button {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    border-radius: 13px;
    border: 0.4px solid #EFF0F2;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
    min-width: 154px;
    flex-shrink: 0;
    height: 49px;
    padding: 7px 0 7px 12px;

    .icon_container {
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
            text-align: center;
        }
    }

    .red_dot {
        position: absolute;
        top: 5px;
        right: 5px;
    }
   
}