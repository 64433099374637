.welcome_screen_outer_container {
  width: 100%;
  text-align: center;

  .welcome_screen_container {
    margin-top: 150px;
    width: 100%;

    h3 {
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      margin-top: 32px;
      color: #200865;
      line-height: 125%; /* 40px */

      @media (min-width: 1000px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 14px;
      font-style: normal;
      margin-top: 8px;
      font-weight: 400;
    }

    @media (min-width: 1000px) {
      margin-top: 80px;
    }

  }

  @media (min-width: 1000px) {
    width: 464px;
    text-align: left;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 170px;

    button {
      width: 100%;
      border-radius: 10px;
      height: 45px;
    }

    @media (min-width: 1000px) {
      margin-top: 92px;
    }

    @media (max-width: 375px) {
      margin-top: 45px;
    }
  }

}
