.menuBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: all 0.5s ease-in-out;

    @media (min-width: 1000px) {
      display: none;
    }
  }

  .menuBtnBurger {
    width: 30px;
    height: 4px;
    background-color: #000;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .menuBtnBurger::before,
  .menuBtnBurger::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background-color: #000;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .menuBtnBurger::before {
    transform: translateY(-9px);
  }

  .menuBtnBurger::after {
    transform: translateY(9px);
  }

  /* ANIMATION */
  .menuBtn.open .menuBtnBurger {
    transform: translateX(-50px);
    background: transparent;
  }

  .menuBtn.open .menuBtnBurger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .menuBtn.open .menuBtnBurger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
