.stepTwo_container {
    width: 100%;

    .stepTwo_header {
        margin-top: 30px;
    }

    .stepTwo_form {
        width: 100%;
        margin-top: 30px;
        text-align: left;
    }

}

.submit_button {
    margin-top: 20px;
    height: 45px;
    color: #fff;
    background-color: #713FFF;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}