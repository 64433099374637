.dashboard {
  overflow-y: auto;

  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  color: #000;
  //font-size: 16px;
  .title {
    //font-size: 24px;
    color: #0d0f11;
    font-weight: 600;
  }

  .walletCard {
    width: 100%;
    height: 132px;
    background: url("../../../../assets/dashboard/Shapes.png")
      var(--primary-deep-color);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 18.3801px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .walletCardMobile {
    //width: 100%;
    height: 15rem;
    background: url("../../../../assets/dashboard/Shapes.png")
      var(--primary-deep-color);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 18.3801px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06) !important;
    border: 0.4px solid #eff0f2 !important;
    cursor: pointer;
    border-radius: 13px !important;
    width: 170px;
    height: 49px !important;
  }

  .cardMobile {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3) !important;
    border: 0.08px solid #eff0f2 !important;
    cursor: pointer;
    border-radius: 13px !important;
    width: 8rem;
    height: 49px;
  }
}

.dashboard {
  .card {
    //width: 20rem;
    height: 6rem;
  }
}

.warningCompleteVirtualAccount {
  background-color: #f8f5ff;
  border: 1px solid #713fff;

  padding: 0.75rem 0.75rem;
  margin: 0.25rem 0.25rem;
}

.proceedNumber {
  border: none;
  color: #713fff;
  font-weight: 700;
  cursor: pointer;

  i {
    margin-left: 0.5rem;
  }
}

.dashboardgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
}

.red_dot {
  position: absolute;
  right: 30px;
  top: 7px;
}
