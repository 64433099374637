.btns {
  display: flex;
  align-items: center;
  gap: 20px;
}

h3 {
  color: #170d33;
  font-size: 20px;
  font-family: "DM Sans";
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
}
