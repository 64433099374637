.layout_container {
    // height: 100vh;
    // overflow: scroll;
    padding: 0 24px;
    color: #200865;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1000px) {
        display: flex;
        justify-content: space-between;
        padding: 0 70px;
        min-height: 100vh;
        // overflow: hidden;
    }
}

.logo__header {
    padding-top: 9px;
    display: flex;
    flex-direction: column;

    div {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .logo {
        font-size: 22.222px;
        color: #200865;

        @media (min-width: 1024px) {
            font-size: 26.133px;
        }
    }

    @media (min-width: 1024px) {
        padding-top: 26px;
    }
}

.svg__section {
    display: none;

    @media (min-width: 1000px) {
        display: flex;
        flex-direction: column;
        background-color: #F8F5FF;
        height: 704px;
        max-width: 530px;
        text-align: center;
        padding: 45px;
        border-radius: 40px 40px 0 0;
        place-self: center;
        margin-top: 50px;

        h2 {
            font-size: 24px;
            font-weight: 700;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            width: 352px;
            margin: 0 auto;
            line-height: 24px
        }
    }

}
