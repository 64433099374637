.quick_actions {
    width: 100%;

    @media (min-width: 1000px) {
        width: 900px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_title {
            color: #0D0F11;
            font-weight: 700;
            font-size: 16px;
            font-style: normal;
            line-height: 150%;
        }

        .button {
            color: #713FFF;
            font-style: normal;
            font-size: 14px;
            line-height: 150%;
            font-weight: 400;
            margin-top: -5px;
        }

        @media (min-width: 1000px) {
            align-items: flex-start;

            .header_title {
                font-size: 24px;
            }

            .button {
                display: none;
            }

        }
    }
}

.button_container {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(154px, 1fr));
    column-gap: 14px;
    row-gap: 24px;

    @media (min-width: 1000px) {
        grid-template-columns: repeat(auto-fit, 154px);
        gap: 24px;
    }
}
