.loanHistory_container {
    width: 100%;

    .transaction_header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            color: #0D0F11;
            font-style: normal;
            font-size: 18px;
            line-height: 150%;
            font-weight: 700;
        }

        .button {
            display: block;
            color: #713FFF;
            font-style: normal;
            font-size: 14px;
            line-height: 150%;
            font-weight: 400;
            margin-top: -5px;
            border: none;
            // background-color: transparent;
        }
    }

    .loan_history_carousel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        button {
            display: none;
        }

        .carousel_container {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 20px;
            position: relative;
            z-index: 3;

            .loanDetailsAndFlag {
                display: flex;
                align-items: center;
                gap: 20px;
            }

            .loan_card {
                display: flex;
                padding: 16px;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                border-radius: 20px;
                border: 1px solid #F4F5F6;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
                background: var(--White, #FFF);
                cursor: pointer;

                .logoAndAmount {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .amountAndDate {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                        .date {
                            color:#8B8699;
                            font-style: normal;
                            font-size: 12px;
                            line-height: normal;
                            font-weight: 400;
                        }
                    }
                }

                .icon_container {
                    height: 34px;
                    width: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: #713FFF;
                    img {
                        text-align: center;
                    }
                }

            }

            .flagActive {
                color: #25AD25;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                background-color: #EAF9EA;
                border-radius: 10px;
                padding: 2px 5px;
                place-self: center;
              }

              .flagPending {
                color: #0822a1;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                background-color: #cdcff5;
                border-radius: 10px;
                padding: 2px 5px;
                place-self: center;
              }

              .flagRejected {
                color: #fe5e2e;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                background-color: #f2a9a1;
                border-radius: 10px;
                padding: 2px 5px;
                place-self: center;
              }

              .flagStale {
                color: #d7d0ce;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                background-color: #282626;
                border-radius: 10px;
                padding: 2px 5px;
                place-self: center;
              }

        }

        @media (min-width: 1000px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            gap: 16px;

            button {
                display: block;
                border: none;
                background-color: transparent;
            }

            .carousel_container {
                flex-direction: row;
                gap: 16px;


                .loan_card {
                    width: 343px;
                }
            }
        }
    }

    @media (min-width: 1000px) {
        .transaction_header {


            .title {
                font-size: 24px;
            }

            .button {
                display: none;
            }
        }
    }
}

.letf_img {
    width: 40px;
    height: 40px;
}
