.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1000px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
  }

  .modal_steps_wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.stepOne_container {
  width: 100%;

  .stepOne_header {
    padding-top: 50px;
    text-align: center;

    @media (min-width: 1000px) {
      padding-top: 0;
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }
  }

  .stepOne_options {
    width: 100%;

    .linkBankstatement {
      width: 100%;
      display: flex;
      padding: 8px;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      gap: 10px;
      background: #fef4e5;

      span {
        text-align: left;
        color: #c37e0b;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
      }

      button {
        padding: 4px 8px;
        border-radius: 24px;
        background: #f5a623;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .new_request {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      border: 0.5px solid #e8e7ea;
      padding: 16px;
      margin-top: 40px;
      cursor: pointer;

      &:hover {
        background-color: #f8f5ff;
      }

      .newLoan_container {
        display: flex;
        align-items: center;
        gap: 16px;

        .icon_container {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-color: #eeebf5;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        text-align: left;
        span {
          &:first-child {
            font-size: 16px;
            font-weight: 700;
            color: #170d33;

            @media (max-width: 280px) and (max-width: 653px) {
              font-size: 16px;
            }
          }

          &:last-child {
            font-size: 14px;
            font-weight: 400;
            color: #8b8699;

            @media (max-width: 280px) and (max-width: 653px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .offers {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      border: 0.5px solid #e8e7ea;
      padding: 16px;
      margin-top: 24px;
      cursor: pointer;

      &:hover {
        background-color: #f8f5ff;
      }

      .newLoan_container {
        display: flex;
        align-items: center;
        gap: 16px;

        .icon_container {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-color: #eeebf5;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        text-align: left;

        position: relative;

        .red_dot {
            position: absolute;
            right: -30px
        }

        .view_history_text {
          font-size: 16px;
          font-weight: 700;
          color: #170d33;

          @media (max-width: 280px) and (max-width: 653px) {
            font-size: 16px;
          }
        }

        span {
          &:last-child {
            font-size: 14px;
            font-weight: 400;
            color: #8b8699;

            @media (max-width: 280px) and (max-width: 653px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    @media (max-width: 280px) and (max-width: 653px) {
      .new_request {
        padding: 8px;
        margin-top: 16px;

        .group1 {
          gap: 8px;

          .userContainer {
            height: 40px;
            width: 40px;
          }
        }
      }

      .offers {
        padding: 8px;
        margin-top: 16px;

        .newLoan_container {
          gap: 8px;

          .icon_container {
            height: 40px;
            width: 40px;
          }
        }
      }
    }

  }
}

.stepTwo_container {
  width: 100%;
  padding-bottom: 20px;
  position: relative;

  .arrow_left {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1000px) {
      top: 0;
      left: 0;
    }
  }

  .stepTwo_header {
    padding-top: 50px;
    text-align: center;

    @media (min-width: 1000px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }
  }

  .isLoading_container {
    width: 100%;
    display: flex;
    padding: 24px;
    gap: 7.106px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #f8f5ff;
  }

  .history_container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;

    .loan_card {
      display: flex;
      padding: 16px;
      width: 100%;
      align-items: center;
      // justify-content: space-between;
      border-radius: 20px;
      border: 1px solid #f4f5f6;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
      background: var(--White, #fff);
      cursor: pointer;

      @media (max-width: 360px) {
        padding: 10px;
      }

      .logoAndAmount {
        width: 100%;
        display: flex;
        align-items: end;
        gap: 16px;

        @media (max-width: 320px) {
          gap: 10px;
        }

        .amountAndDate {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          position: relative;

          .amount {
            color: #170d33;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
          }

          .red_dot {
            position: absolute;
            top: -10px;
            right: -20px;
          }

          .date {
            color: #8b8699;
            font-style: normal;
            font-size: 12px;
            line-height: normal;
            font-weight: 400;

            @media (max-width: 320px) {
              font-size: 10px;
            }
          }
        }
      }

      .icon_container {
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #713fff;
        img {
          text-align: center;
        }
      }

      .flagNrefNo {
        display: flex;
        flex-direction: column;
        align-items: start;

        .loanRef {
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
      }

      .arrow {
        margin-left: 40px;

        @media (max-width: 414px) {
          margin-left: 10px;
        }
      }
    }

    .flagActive {
      color: #25ad25;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      background-color: #eaf9ea;
      border-radius: 10px;
      padding: 2px 5px;
      place-self: center;
    }

    .flagPending {
      color: #0822a1;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      background-color: #cdcff5;
      border-radius: 10px;
      padding: 2px 5px;
      place-self: center;
    }

    .flagRejected {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      color: #ac2212;
      background-color: #f2a9a1;
      border-radius: 10px;
      padding: 2px 5px;
      place-self: center;
    }

    .flagStale {
      color: #d7d0ce;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      background-color: #282626;
      border-radius: 10px;
      padding: 2px 5px;
      place-self: center;
    }
  }
}

.pagination_controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 8px;

  button {
    display: flex;
    height: 32px;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #f1f1f1;

    &:first-child,
    &:last-child {
      border: none;
    }

    &:nth-child(2) {
      background: #713fff;
      color: #fff;
    }

    &:nth-child(5) {
      border: none;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.alert {
  width: 100%;
  padding-bottom: 20px;
  padding-top: 30px;

  @media (min-width: 1000px) {
    padding-top: 10px;
  }
}
