.modal_container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 500px);
    width: 90%;
    background-color: #FFF;
    border-radius: 20px;
    border-top: 4px solid #CFBEFF;
    box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.10);
    padding: 10px;
    position: fixed;
    overflow: scroll;
    z-index: 5;

    .modal_wrapper {
        .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #bab7c5;

            h4 {
                font-size: 18px;
            }

            .cancel_button {
                padding-bottom: 5px;
            }
        }

        .form {
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            .startDate {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
            }

            .endDate {
                display: flex;
                flex-direction: column;
            }

            input {
                appearance: none;
                height: 40px;
                border-radius: 5px;
                padding: 0 10px;
                border: 1px solid #8B8699;
            }

            .clearBtn {
                margin-top: 10px;
                place-self: end;
            }
        }

        .btn {
            margin-top: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                width: 100%;
                color: #FFF;
                background-color: #200865;
                height: 50px;
                border-radius: 10px;
            }
        }
    }
}