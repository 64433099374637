.transaction_container {
    width: 750px;
    height: 100dvh;
    overflow: hidden;

    .transaction_header {

        button {
            color: #8B8699;
            font-style: normal;
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 8px;
            border: none;
            background-color: transparent;
        }

        .title {
            color: #0D0F11;
            font-style: normal;
            font-size: 24px;
            line-height: 150%; 
            font-weight: 700;
            margin-top: 30px;
        }
    }
    .transaction_Content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .pagination_controls {
            display: flex;
            align-items: center;
            margin-top: 20px;
            gap: 8px;

            button {
                display: flex;
                height: 32px;
                width: 32px;
                flex-direction: column;
                padding: 10px;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid #F1F1F1;

                &:first-child, &:last-child {
                    border: none;
                }

                &:nth-child(2) {
                    background: #713FFF;
                    color: #fff;
                }

                &:nth-child(5) {
                    border: none;
                }
            }
        }

        table {
            display: block;
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            overflow: hidden;
        
            .table_header {
                width: 100%;
                background-color: #F2F5F9;
                // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                overflow: hidden;

            }

            .content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                padding: 10px 0;
            }

            .viewInfo {
                cursor: pointer;
            }

            tr {
                &:hover td:not(:last-child) {
                    background-color: #F2F5F9;
                }
            }
        
            th, td {
                text-align: left;
                width: 25%;
                // padding: 24px;
            }
        
            th {
                padding: 12px 24px;
                border-top-left-radius: 10px;
        
                &:nth-child(2) {
                    border-top-left-radius: 0;
                }
        
                &:nth-child(3) {
                    border-top-left-radius: 0;
                    border-top-right-radius: 10px;
                }
            }

            td {
                padding: 20px 20px;
            }

        }
    }
    
}

.transaction_amount__credit {
    color: #25AD25;
}