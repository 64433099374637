.container {
    height: 100dvh;
    margin-top: 100px;
    width: 100%;
    // overflow: scroll;

    @media (min-width: 1000px) {
        display: none;
        margin: 0;
    }

    .transaction_header {
        padding-top: 20px;

        .button {
            display: flex;
            align-items: center;
            gap: 10px;
            border: none;
            background-color: transparent;

            .arrow {
                margin-top: -8px;
                width: 24px;
                height: 24px;
            }
        }

        .title {
            color: #200865;
            font-style: normal;
            font-size: 24px;
            line-height: 125%;
            font-weight: 700;
            padding-top: 20px;
        }

        .range {
            width: 100%;
            border: 1px solid #8B8699;
            border-radius: 5px;
            height: 50px;
        }
    }

    .transactions {
        width: 100%;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .transaction {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EEE;
            padding-bottom: 8px;

            .icon_title_wrap {
                display: flex;
                align-items: center;
                gap: 16px;

                .depositIcon_wrap {
                    width: 40px;
                    flex-shrink: 0;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #EAFBF1;
                    border-radius: 50%;
                }

                .deposit_date {
                    display: flex;
                    flex-direction: column;

                    .transaction_type {
                        color: #3A3A3A;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 140%;
                        font-weight: 500;
                    }

                    .transaction_date {
                        color: #8B8699;
                        font-style: normal;
                        font-size: 12px;
                        line-height: 100%;
                        font-weight: 400;
                    }
                }
            }

            .deposit_amount {
                align-self: flex-start;
                color: #27BE63;
                font-style: normal;
                font-size: 14px;
                line-height: 150%;
                font-weight: 500;
            }

            .withdrawal_amount {
                align-self: flex-start;
                color: #E43535;
                font-style: normal;
                font-size: 14px;
                line-height: 150%;
                font-weight: 500;
            }
        }
    }

    .noTransaction_container {
        width: 100%;
        height: calc(100% - 150px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
        padding: 0 50px;
        text-align: center;

        h3 {
            font-size: 20px;
        }
    }

    .flagActive {
        color: #25AD25;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        border-radius: 10px;
        padding: 2px 5px;
        place-self: center;
      }

      .flagPending {
        color: #0822a1;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        border-radius: 10px;
        padding: 2px 5px;
        place-self: center;
      }

      .flagRejected {
        color: #fe5e2e;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        border-radius: 10px;
        padding: 2px 5px;
        place-self: center;
      }

      .flagStale {
        color: #d7d0ce;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        background-color: #282626;
        border-radius: 10px;
        padding: 2px 5px;
        place-self: center;
      }
}

.pagination_controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: 8px;

    button {
        display: flex;
        height: 32px;
        flex-direction: column;
        padding: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid #F1F1F1;

        &:first-child, &:last-child {
            border: none;
        }

        &:nth-child(2) {
            background: #713FFF;
            color: #fff;
        }

        &:nth-child(5) {
            border: none;
        }
    }
}
