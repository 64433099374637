.container_accounts {
  background-color: #f8f5ff;
  border-radius: 12px;
  padding: 16px 24px 16px 24px;

  .account_number {
    font-weight: 700;
    font-size: 14px;
    color: #200865;
  }
  .account_name {
    font-weight: 400;
    font-size: 12px;
    color: #200865;
  }
  .bank_name {
    font-weight: 400;

    font-size: 14px;
    color: #200865;
  }
}
