.directDebit_container {
  position: relative;
  width: 100%;

  h3 {
    color: #170D33;
    font-size: 20px;
    font-family: "DM Sans";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
  }
}

.marginTop {
  margin-top: 40px;
}

.alignLeft {
  text-align: left;
}

.selectBankContainer {
  width: 100%;
  position: relative;
  margin-bottom: 8px;
  // @media (min-width: 400px) and (max-width: 414px) {
  //     margin-top: -40px;
  // }
  // @media (min-width: 416px) and (max-width: 470px) {
  //     margin-top: -58px;
  // }
  .bankNameDropdown {
    max-height: 150px;
    position: absolute;
    background-color: #fff;
    overflow-y: auto;
    color: #000;
    list-style: none;
    z-index: 100;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .selectedBankField {
    width: 100%;
    border: 1px solid #d1cfd6;
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 16px;
    &::placeholder {
      color: #8c98a4;
      opacity: 1;
    }
  }
  .bank_error {
    display: inline-block;
    color: rgb(205, 3, 3);
    margin-top: 4px;
    font-size: 13px;
  }
}

.container_accounts {
  background-color: #f8f5ff;
  border-radius: 12px;
  padding: 16px 24px 16px 24px;

  .account_number {
    font-weight: 700;
    font-size: 14px;
    color: #200865;
  }
  .account_name {
    font-weight: 400;
    font-size: 12px;
    color: #200865;
  }
  .bank_name {
    font-weight: 400;

    font-size: 14px;
    color: #200865;
  }
}

.submit__btn {
  margin-top: 70px;
  // margin-bottom: 68px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;

  button {
    height: 50px;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }
}

.left {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
