.otp_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
    gap: 5px;

    @media (min-width: 1024px) {
        gap: 10px;
    }

    .code {
        border-radius: 10px;
        border: 2px solid #D1CFD6;
        background: #FFF;
        height: 50px;
        width: 50px;
        text-align: center;

        &:focus {
            border-color: #713FFF;
        }

        @media (max-width: 460px) {
            width: 37px;
            height: 37px;
        }
    }

}